<template>
  <div class="container container-top">
    <div class="pc">
      <div class="main padding-top-44 h5">자주 묻는 질문</div>
      <!-- 카테고리 -->
      <div class="flex-align margin-top-24 cat-wrapper">
        <div class="cat-st unselect body2-medium" v-for="(cat, cat_idx) in faq_category"
             :class="{'cat-selected': cat.selected}"
             @click="clickCat(cat)"
             :key="`cat-${cat_idx}`">{{ cat.label }}</div>
      </div>
      <div class="lp-divider-sub5 padding-top-24"></div>

      <faq-item v-for="faq_item in faq" :key="`faq-${faq_item.id}`" :faq="faq_item"></faq-item>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="faqFiltered" @getData="getData" class="margin-top-40"></lp-paginate>
    </div>
    <div class="mobile">
      <div class="main padding-top-24 h7">자주 묻는 질문</div>
      <!-- 카테고리 -->
      <div class="flex-align margin-top-24 cat-wrapper">
        <div class="cat-st unselect body4-medium" v-for="(cat, cat_idx) in faq_category"
             :class="{'cat-selected': cat.selected}"
             @click="clickCat(cat)"
             :key="`cat-${cat_idx}`">{{ cat.label }}</div>
      </div>
      <div class="lp-divider-sub5 padding-top-24"></div>

      <faq-item v-for="faq_item in faq" :key="`faq-${faq_item.id}`" :faq="faq_item"></faq-item>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="faqFiltered" @getData="getData" class="margin-top-40"></lp-paginate>
    </div>
  </div>
</template>
<script>
  import FaqItem from "../module/FaqItem";
  import LpPaginate from "../component/LpPaginate";
  export default {
    name: "Faq",
    components: {LpPaginate, FaqItem},
    created() {
      this.getFaqCategory();
      this.getData();
    },
    data() {
      return {
        faq_category: undefined,
        faq: undefined,
        faqFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        }
      }
    },
    methods: {
      clickCat(cat) {
        this.faq_category.forEach(item=>{
          item.selected = false;
        })
        cat.selected = true;
        if(cat.value) {
          this.faqFiltered.category_id = cat.value;
          this.getData();
        } else {
          delete this.faqFiltered.category_id;
          this.getData();
        }
      },
      getFaqCategory() {
        this.$axios.get('public/board/faq/category').then(res => {
          let list = [];
          list = [{label:'전체',value:''}];
          list = list.concat(res.data);
          list.forEach(item=>{
            item.selected = false;
          })
          this.faq_category = list;
          this.faq_category[0].selected = true;
        })
      },
      getData() {
        this.$axios.get('public/board/faq', {
          params: this.faqFiltered
        }).then(res => {
          this.faq = res.data.data;
          this.faqFiltered.page_length = res.data.page_length;
          this.faqFiltered.cur_page = res.data.cur_page;
          this.faqFiltered.total_page = res.data.total_page;
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .cat-wrapper
    overflow-x scroll
    -ms-overflow-style none /* IE and Edge */
    scrollbar-width none /* Firefox */

  .cat-wrapper::-webkit-scrollbar
    display none /* Chrome, Safari, Opera*/

  .cat-st
    padding 8px 16px
    background-color $gray3
    border-radius 50px
    color $sub3
    margin-right 12px
    flex 0 0 auto

  .cat-selected
    background-color $primary
    color white
</style>